/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import { navigate } from "gatsby"
import { Auth0Provider } from "@auth0/auth0-react"
import "./src/styles/global.css"

// Use Gatsby's navigate method to replace the URL
const onRedirectCallback = appState => {
	navigate(appState?.returnTo || "/")
}

export const wrapRootElement = ({ element }) => {
	return (
		<Auth0Provider
			domain={process.env.GATSBY_AUTH0_DOMAIN}
			clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
		>
			{element}
		</Auth0Provider>
	)
}

export const onInitialClientRender = () => {
	console.log("Gatsby Hydrated")
}
